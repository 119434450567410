import React from 'react';
import { Link } from 'react-router-dom';


const Contact = () => {
  return (
    <div className="content">
        <div className="thee3-company">
            <Link to="/">
                <img src="/thee3logo.png" alt="Company Logo" className="thee3-logo" />
            </Link>
            <h1>Contact Us</h1>
            <hr />
            <p>We are The Emphasis Elevated or Thee3. <br/>We're always up for a chat. Reach out to us anytime for questions, feedback, or support!</p>

            <p>You can contact us via email at:</p>

            <p><a style={{color: "rgb(13, 136, 196)"}} href="mailto:info@theemphasiselevated.com">info@theemphasiselevated.com</a></p>

            <p>or reach out to us via phone call at:</p>

            <p><a style={{color: "rgb(13, 136, 196)"}} href="tel:1-855-708-3214">1-855-708-3214</a></p>

            <p>Thanks for visiting!</p>
        </div>
    </div>
  );
};

export default Contact;