import React from 'react';
import { Link } from 'react-router-dom';


const eWordArray = [
    "Exceptionally", "Efficiently", "Excellently", "Expertly", "Effectively", 
    "Ecstatically", "Elegantly", "Energizingly", "Enthusiastically", "Exquisitely", 
    "Experimentally", "Exhaustively", "Expeditiously", "Exuberantly", "Eagerly", 
    "Emotionally", "Endlessly", "Enduringly", "Energizingly", "Entertainingly", "Effortlessly", "Easily"
];
let eWordIndex = 0;
function Home() {
    React.useEffect(() => {
        const showEWord = () => {
            if(eWordIndex >= eWordArray.length) {
                eWordIndex = 0;
            }
            const eWordElement = document.querySelector(".changing-words");
            if(eWordElement != null) {
                eWordElement.textContent = eWordArray[eWordIndex];
                eWordIndex += 1;
            }
        };
        const interval = setInterval(showEWord, 7000);
        return () => clearInterval(interval);
    }, []);
    return (
        <div className="content">
            <div className="thee3-company">
                <Link to="/">
                    <img src="/thee3logo.png" alt="Company Logo" className="thee3-logo" />
                </Link>
                <p className="slogan">The Emphasis Elevated <span className="changing-words">Effervescently</span>
                </p>
                <hr />
                <p className="description">We are living in a time of unprecedented excitement and innovation, where the possibilities are endless and the opportunities are vast. We are on the cusp of a technological revolution that will change the world as we know it, and we are thrilled to be a part of it. From the rise of automation and AI, to the development of new technologies that will make things easier and more enjoyable, we are committed to being at the forefront of this revolution. We see a future where technology is not just a tool, but a partner in journey, helping us to learn and grow in ways that were previously unimaginable. And we are committed to being a part of it. Our mission is to bring the best of comfort, excitement, and innovation together, making every moment more inspiring and enjoyable. </p>
            </div>
            <div className="options-container">
                <div className="thee3-option store-option">
                    <h2 className="opt-desc"><i>Store (coming soon)</i></h2>
                    <p className="opt-desc2">Experience the most exclusive and exciting buys in tech today! We accumulate a selection of hottest new gadgets!</p>
                </div>
                <div className="thee3-option blog-option">
                    <h2 className="opt-desc"><i>Blog (coming soon)</i></h2>
                    <p className="opt-desc2">Read the latest updates on our products, services and vision for the future of technology and innovation.</p>
                </div>
            </div>
        </div>
    );
}

export default Home;